import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import get from 'lodash.get';
import { graphql } from 'gatsby';
import { Page } from '../components';
import { getMetadata } from '../helpers';

const IndexPage = (props = {}) => {
  const {
    data = {},
  } = props;
  const pageData = get(data, 'allContentfulPage.edges[0].node', {});

  const pageProps = {
    contentModules: get(pageData, 'contentModules', {}),
  };

  return (
    <Fragment>
      <Helmet
        title={get(pageData, 'metaTitle', '')}
        meta={getMetadata(pageData)}
      />
      <Page {...pageProps} />
    </Fragment>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default IndexPage;

export const query = graphql`
  query {
    allContentfulPage(filter: {slug: {eq: "homepage"}}, limit: 1) {
      edges {
        node {
          contentful_id
          slug
          title
          metaTitle,
          metaDescription {
            metaDescription
          }
          ogType,
          ogImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          contentModules {
            __typename
            ... on ContentfulCollectionTiles {
              contentful_id
              tiles {
                id
                caption
                contentful_id
                link
                size
                target
                title
                description {
                  description
                }
                image {
                  title
                  description
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
            ... on ContentfulHero {
              contentful_id
              displayInline
              displayOnce
              image {
                description
                title
                file {
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              imageMobile {
                title
                description
                file {
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              heading {
                heading
              }
            }
          }
        }
      }
    }
  }
`;
